import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image, Span, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | Pyrvo Rentals'a Hoş Geldiniz
			</title>
			<meta name={"description"} content={"Kentsel hareketliliğin anahtarı Pyrvo Rentals ile şehrin ritmini serbest bırakın"} />
			<meta property={"og:title"} content={"Ana Sayfa | Pyrvo Rentals'a Hoş Geldiniz"} />
			<meta property={"og:description"} content={"Kentsel hareketliliğin anahtarı Pyrvo Rentals ile şehrin ritmini serbest bırakın"} />
			<meta property={"og:image"} content={"https://pyrvo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://pyrvo.com/img/a463247.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://pyrvo.com/img/a463247.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://pyrvo.com/img/a463247.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://pyrvo.com/img/a463247.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://pyrvo.com/img/a463247.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://pyrvo.com/img/a463247.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://pyrvo.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Pyrvo Rentals
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					max-width="1200px"
				>
					Şehir manzaralarında kolaylıkla gezinmek için gideceğiniz yer olan Pyrvo Rentals ile şehrin kalbine adım atın. Kompakt taşıtlardan geniş crossover'lara kadar çeşitlilik gösteren filomuz, şehir içi sürüş deneyiminizi yükseltmek için tasarlanmıştır ve her yolculuğun şehir sokakları kadar dinamik olmasını sağlar.
				</Text>
				<Link
					href="/contacts"
					padding="12px 44px 12px 44px"
					color="--light"
					background="--color-primary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-grey"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Bize Ulaşın
				</Link>
			</Box>
		</Section>
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				font="--base"
				text-transform="uppercase"
				letter-spacing="1px"
				color="--grey"
				margin="0px"
				width="100%"
			>
				Hoş Geldiniz
			</Text>
			<Text
				as="h1"
				margin="8px 0px 32px"
				font="--headline1"
				md-font="--headline2"
				color="--dark"
				width="100%"
			>
				Pyrvo Rentals'ın Heyecanını Keşfedin
			</Text>
			<Text
				margin="0px"
				max-width="600px"
				font="--base"
				color="--grey"
				padding="0px 16px 0px 0px"
				width="66.66%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 24px 0px"
			>
				Şehir manzaralarında kolaylıkla gezinmek için gideceğiniz yer olan Pyrvo Rentals ile şehrin kalbine adım atın. Kompakt taşıtlardan geniş crossover'lara kadar çeşitlilik gösteren filomuz, şehir içi sürüş deneyiminizi yükseltmek için tasarlanmıştır ve her yolculuğun şehir sokakları kadar dinamik olmasını sağlar.
			</Text>
			<Text
				margin="0px 0px 0px auto"
				font="--base"
				color="--grey"
				width="20%"
				md-width="100%"
				md-margin="0px 0px 32px 0px"
			>
				Titizlikle korunan araçlarımızın direksiyonunda kentsel keşif heyecanını yaşayın. İster en sevdiğiniz kafeye gitmek ister şehir merkezindeki bulvarlarda manzaralı bir sürüş olsun, Pyrvo Rentals her metropol macerası için mükemmel bir sürüş sağlar.
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL1"
			overflow-y="hidden"
			overflow-x="hidden"
			quarkly-title="Advantages/Features-12"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Box
					width="972px"
					height="589px"
					md-display="flex"
					md-justify-content="center"
					box-shadow="--xl"
					lg-height="auto"
				>
					<Image
						height="589px"
						lg-width="100%"
						width="972px"
						border-radius="8px"
						z-index="9"
						position="relative"
						lg-margin="0px 0px 0px 0px"
						box-shadow="--xxl"
						src="https://pyrvo.com/img/2.jpg"
						object-fit="cover"
						lg-height="auto"
						lg-display="block"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 20px 0px"
				sm-margin="32px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				lg-padding="36px 0px 16px 0px"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-max-width="720px"
				>
					Neden Pyrvo Rentals'ı Seçmelisiniz?
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--light"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								Sorunsuz Erişilebilirlik.{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Şehir genelinde birden fazla elverişli konum ile ideal aracınıza erişmek zahmetsizdir.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--darkL2"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								Özel Şehir Çözümleri.{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Filomuz, kalabalık caddelerde ve dar park yerlerinde gezinmek için özenle seçilmiştir ve şehir yaşamına uyarlanmış kusursuz bir sürüş deneyimi sunar.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" lg-max-width="720px">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								Müşteri Odaklı Yaklaşım.{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Pyrvo Rentals'da memnuniyetiniz bizim önceliğimizdir. Benzersiz ihtiyaçlarınızı karşılamak için esnek kiralama seçeneklerinin ve kişiselleştirilmiş hizmetin keyfini çıkarın.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" lg-max-width="720px">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--light"
							>
								Güvenlik Güvencesi.{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--light"
							>
								Her aracın kapsamlı denetimlerden geçtiğini bilerek güvenle sürün ve yolda güvenliğinizi sağlayın.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});